import { Ability, AbilityClass, detectSubjectType } from '@casl/ability';
import AccountRoles from '@/types/AccountRoles';
import AccountTypes from '@/types/AccountTypes';

// Rules
import userDefinedRules from './user-defined.rules.json';
import adminRules from './admin.rules.json';
import managerRules from './manager.rules.json';
import contractorRules from './contractor.rules.json';
import distributorRules from './distributor.rules.json';
import branchRules from './branch.rules.json';
import saasRules from './saas.rules.json';
import saasAdminRules from './saas-admin.rules.json';

type Actions =
  | 'create'
  | 'read'
  | 'update'
  | 'delete'
  | 'suspend'
  | 'restore'
  | 'access'
  | 'display'
  | 'resend-invite';

type Subjects =
  | 'UserAccount'
  | 'AccountActions'
  | 'AccountSuspendAction'
  | 'Account'
  | 'PrimaryContact';

export type AppAbilityType = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbilityType>;

export function defineRulesFor(user: any): Array<any> {
  const rules: Array<any> = [];

  if (!user) {
    return rules;
  }

  const userDefinedRuleset = JSON.parse(JSON.stringify(userDefinedRules));
  rules.push(...userDefinedRuleset);

  if (user.role === AccountRoles.ADMIN) {
    const adminRuleset = JSON.parse(
      JSON.stringify(adminRules).replace(
        /@user\.email_address/g,
        user.email_address
      )
    );
    rules.push(...adminRuleset);
  } else if (user.role === AccountRoles.MANAGER) {
    const managerRuleset = JSON.parse(
      JSON.stringify(managerRules).replace(/@user\.userid/g, user.userid)
    );
    rules.push(...managerRuleset);
  }

  switch (user.account_type) {
    case AccountTypes.SASS_OPS:
      // eslint-disable-next-line no-case-declarations
      const saasRuleset = JSON.parse(
        JSON.stringify(saasRules).replace(
          /@user\.account_uuid/g,
          user.account_uuid
        )
      );
      rules.push(...saasRuleset);
      if (user.role === AccountRoles.ADMIN) {
        const sassAdminRuleSet = JSON.parse(
          JSON.stringify(saasAdminRules).replace(
            /@user\.account_uuid/g,
            user.account_uuid
          )
        );
        rules.push(...sassAdminRuleSet);
      }
      break;
    case AccountTypes.CONTRACTOR:
      console.log('Loading CONTRACTOR');
      // eslint-disable-next-line no-case-declarations
      const contractorRuleSet = JSON.parse(
        JSON.stringify(contractorRules).replace(
          /@user\.account_uuid/g,
          user.account_uuid
        )
      );
      rules.push(...contractorRuleSet);
      break;
    case AccountTypes.BRANCH:
      console.log('Loading BRANCH');
      // eslint-disable-next-line no-case-declarations
      const branchRuleSet = JSON.parse(
        JSON.stringify(branchRules).replace(
          /@user\.account_uuid/g,
          user.account_uuid
        )
      );
      rules.push(...branchRuleSet);
      break;
    case AccountTypes.DISTRIBUTOR:
      console.log('Loading DISTRIBUTOR');
      // eslint-disable-next-line no-case-declarations
      const distributorRuleSet = JSON.parse(
        JSON.stringify(distributorRules).replace(
          /@user\.account_uuid/g,
          user.account_uuid
        )
      );
      rules.push(...distributorRuleSet);
      break;
  }
  return rules;
}

export function builtAbilityFor(user: any): any {
  return new AppAbility(defineRulesFor(user), {
    detectSubjectType: (subject: any) => {
      if (subject && typeof subject === 'object' && subject.__type) {
        return subject.__type;
      }
      return detectSubjectType(subject);
    }
  });
}
