import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import { getEndpoint } from '@/utils/Helpers';
import messages from '../lang/';

Vue.use(VueI18n);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const missing = (locale, key, vm) => {
  if (
    key.match(/^rest_api.response.error.*/) &&
    key !== 'rest_api.response.error.GENERAL_EXCEPTION'
  ) {
    return vm.$t('rest_api.response.error.GENERAL_EXCEPTION');
  }
  return key;
};

const availableLanguages = [
  'de',
  'el',
  'es',
  'fr',
  'it',
  'nl',
  'pl',
  'pt',
  'ru',
  'tr',
  'zh'
];

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'fr',
  messages,
  missing
});

export default i18n;

export function loadMessages(): Promise<any> {
  const locales = availableLanguages.map(async (locale) => {
    try {
      const localeFile = await axios.get(
        `${getEndpoint(process.env.VUE_APP_ENDPOINT, false)}locale/${locale}`
      );
      i18n.setLocaleMessage(
        locale,
        JSON.parse(JSON.stringify(localeFile.data))
      );
      return { [locale]: localeFile.data };
    } catch (error) {
      console.log(error);
      return {};
    }
  });
  return Promise.all(locales);
}
