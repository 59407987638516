
  import { Component, Vue } from 'vue-property-decorator';

  import ASCXHeader from '@rdss-mono/ascx-header';
  import ASCXNavbar from '@rdss-mono/ascx-navbar';
  import eventBus from '@/utils/EventBus';
  import CookiePolicy from '@/components/modals/cookie-policy/CookiePolicyModal.vue';
  import MessageBoxModal from '@/components/modals/message-box-modal/MessageBoxModal.vue';
  import ASCXFooter from '@rdss-mono/ascx-footer';
  import ASCXToast from '@rdss-mono/ascx-toast';
  import Version from '@/version';
  import { checkTokenExpiration, supportedLanguages } from '@/utils/Helpers';
  import { loadMessages } from './i18n';
  import LoaderOverlay from 'vue-loading-overlay';

  import '*/scss/components/_header.scss'; // NAVPILLS HAS A DEPENDECY
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @Component({
    components: {
      ASCXHeader,
      MessageBoxModal,
      ASCXNavbar,
      ASCXFooter,
      ASCXToast,
      LoaderOverlay,
      CookiePolicy
    }
  })
  export default class extends Vue {
    /**
     * Checks if the user exists in the local storage, if it does then
     * it updates the store to resolve the Auth and the set the user data.
     */
    version = Version;
    private logoutModalId = 'modalUserSuspended';
    private unauthorizedModalId = 'modalUnauthorized';

    // Timer variables, safe to delete when user session is implemented.
    private refreshInterval = null;
    private retryInterval = null;

    private headerLogoSrc = require('../assets/logos/ASC-AccountManager-Logo.svg');
    private headerLogoMobileSrc = require('../assets/logos/ASC-AccountManager-Logo-Mobile.svg');

    private toastBody = {
      toastTitle: '',
      toastMessage: '',
      variant: '',
      icon: ''
    };
    private showToast = false;
    private showLangLoader = true;
    public showCookiePolicy = false;
    private languages = supportedLanguages;

    private beforeMount() {
      loadMessages().finally(() => {
        this.showLangLoader = false;
      });
    }

    private created() {
      // Registers event bus to listen to Http Errors.
      eventBus.$on('userSuspended', () => {
        if (this.$route.path != '/user/login') {
          this.$bvModal.show(this.logoutModalId);
        }
      });

      eventBus.$on('userUnauthorized', () => {
        if (this.$route.path != '/user/login') {
          this.$bvModal.show(this.unauthorizedModalId);
        }
      });

      /**
       * On log out clears clears intervals and re-assigns the variables
       * to undefined.
       *
       * Safe to delete when user session is implemented
       */
      eventBus.$on('clearTimers', () => {
        clearInterval(this.refreshInterval);
        clearInterval(this.retryInterval);
        this.refreshInterval = undefined;
        this.retryInterval = undefined;
      });

      /**
       * Makes a call to refresh_tokens when the user is logged in and the access_token
       * time to live is less than 8 minutes.
       * Checks for the TTL of the token every 4 minutes.
       * Code safe to delete when the session management is implemented.
       */
      eventBus.$on('setTokenTimer', () => {
        // 4 minutes is 4 x 60000ms
        const refreshTimer = 4 * 60000;
        // 8 minutes is 8 x 60000ms
        const eightMinutes = 8 * 60000;

        let times = 0;
        this.refreshInterval = setInterval(async () => {
          const localData = JSON.parse(localStorage.getItem('acme-session'));
          const tokenTTL = checkTokenExpiration(localData.access_token);

          const requestToken = async () => {
            return this.refreshToken()
              .then(({ data }) => {
                const { id_token, access_token } = data;
                clearInterval(this.retryInterval);
                times = 0;
                const session = {
                  ...localData,
                  id_token,
                  access_token
                };
                localStorage.setItem('acme-session', JSON.stringify(session));
              })
              .catch((error) => {
                throw error;
              });
          };

          if (tokenTTL < eightMinutes) {
            try {
              if (!this.retryInterval) {
                await requestToken();
              }
            } catch (err) {
              // 60000 ms is a minute, retry every minute
              this.retryInterval = setInterval(async () => {
                try {
                  await requestToken();
                } catch (error) {
                  if (++times > 2) {
                    times = 0;
                    eventBus.$emit('clearTimers');
                    eventBus.$emit('userUnauthorized');
                  }
                }
              }, 60000);
            }
          }
        }, refreshTimer);
      });

      eventBus.$on(
        'showToast',
        (
          toastTitleI18nKey = '',
          toastMessageI18nKey = '',
          variant = '',
          appendToTitle = '',
          icon = ''
        ) => {
          this.toastBody.toastTitle = `${this.$t(
            toastTitleI18nKey
          )} ${appendToTitle}`;
          this.toastBody.toastMessage = this.$t(toastMessageI18nKey).toString();
          this.toastBody.variant = variant;
          this.toastBody.icon = icon;

          this.showToast = true;
        }
      );

      // TODO: Remove localStorage when the session management is sorted out
      // Call the user route when the it is implemented on the API as well.
      const isReloading = sessionStorage.getItem('isReloading');
      if (isReloading) {
        const session = JSON.parse(localStorage.getItem('acme-session'));
        if (session != null && session.user != null) {
          this.$store.dispatch('loadUserData', session.user);
          this.$store.commit('updateAuth', true);
          // Sets the timer in case user refreshed the page.
          eventBus.$emit('setTokenTimer');
        }
      } else {
        localStorage.clear();
        sessionStorage.setItem('isReloading', 'true');
      }
      this.onUserIdle(1200000, this);
    }

    private refreshToken(): Promise<any> {
      const localData = JSON.parse(localStorage.getItem('acme-session'));
      const refreshToken = JSON.parse(
        localStorage.getItem('acme-refresh-token')
      );
      return this.$http.post(`/user/${localData.username}/refresh_tokens`, {
        refresh_token: refreshToken.refresh_token
      });
    }

    private async logoutUser() {
      await this.$store.dispatch('logout');
      this.$router.push({ name: 'login' }).then(() => {
        this.$bvModal.hide(this.logoutModalId);
        this.$bvModal.hide(this.unauthorizedModalId);
      });
    }

    private get unauthorizedMessage() {
      return {
        status: `${this.$t('component.logged-out')}`,
        message: `${this.$t('rest_api.response.error.UNAUTHORIZED_REQUEST')}`,
        buttonText: `${this.$t('button.ok')}`
      };
    }

    private get logoutMessage() {
      return {
        status: `${this.$t('errors.account_suspended')}`,
        message: `${this.$t('rest_api.response.error.ACME_ACCOUNT_SUSPENDED')}`,
        buttonText: `${this.$t('button.return_login')}`
      };
    }

    // ASCXHeader

    private async requestLogout() {
      const session = JSON.parse(localStorage.getItem('acme-session'));
      if (session === null) {
        await this.logoutUser();
        return;
      }

      const { refresh_token } = JSON.parse(
        localStorage.getItem('acme-refresh-token')
      );

      const username = this.$store.getters.user.email_address;
      this.$http({
        url: `/user/${username}/logout`,
        timeout: 3000,
        method: 'POST',
        data: {
          refresh_token
        }
      }).finally(async () => {
        await this.logoutUser();
      });
    }

    // ASCXNavbar
    get breadcrumbs(): Array<any> {
      return this.$store.getters.breadcrumbs;
    }

    onGoTo(urlObj: Record<string, any>): void {
      if (
        !(
          this.$route.path.includes(urlObj.path) ||
          this.$route.name.includes(urlObj.name)
        )
      ) {
        this.$router.push(urlObj);
      }
    }

    // ASCXFooter
    get gitHash(): string {
      const { tag, hash, branch } = this.version;
      if (tag === undefined) {
        return `${branch}.(${hash})`;
      }
      return `${this.$t('auth.footer.build')} ${tag}.(${hash})`;
    }

    get language(): string {
      return this.$store.getters.language ? this.$store.getters.language : 'en';
    }

    get copyright(): string {
      const currentYear = new Date().getFullYear();
      return `Ⓒ ${currentYear} ${this.$t('texts.fujitsu_copyright')}`;
    }

    private translate(key: string): string {
      return this.$t(key).toString();
    }
    private changeLang(choosedLang: string): void {
      if (choosedLang && this.language !== choosedLang) {
        this.$store.commit('updateLanguage', choosedLang);
        this.$store.dispatch('changeFooterSelection', true);
      }
    }

    private needHelpAction() {
      this.$router.push({ name: 'NeedHelp' });
    }

    private logoClicked() {
      window.open('https://www.fujitsu-general.com/', '_blank', 'noopener');
    }

    // Idle user time and close tab handler
    private async onUserIdle(timeout: number, vm): Promise<void> {
      let timer: number;
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;

      function resetTimer() {
        clearTimeout(timer);
        timer = setTimeout(() => {
          if (vm.$store.getters.authResolved) {
            vm.$bvModal.show(vm.unauthorizedModalId);
          }
        }, timeout);
      }
    }

    // ASCXToast
    private dismissToast(): void {
      this.showToast = false;
    }
  }
