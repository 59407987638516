import http from '@/utils/Http';
import * as _ from 'lodash';

const defaultState = Object.freeze({
  // needed states
});

const mutations = {
  resetState(state): void {
    Object.assign(state, _.cloneDeep(defaultState));
  }
};

const actions = {
  resetStore({ commit }): void {
    commit('resetState');
  },
  // eslint-disable-next-line
  login({ commit }, { email, password }): Promise<any> {
    return http.post(`/user/${email}/login`, {
      password
    });
  },
  mfaChallenge(
    // eslint-disable-next-line
    { commit },
    {
      mfaCode,
      challengeName,
      sessionId,
      username
    }: {
      mfaCode: string;
      challengeName: string;
      sessionId: string;
      username: string;
    }
  ): Promise<any> {
    return http.post(`/user/${username}/auth_challenge_response`, {
      auth_challenge_name: challengeName,
      auth_challenge_session_id: sessionId,
      mfa_code: mfaCode
    });
  },
  newPasswordChallenge(
    // eslint-disable-next-line
    { commit },
    {
      challengeName,
      sessionId,
      username,
      password
    }: {
      challengeName: string;
      sessionId: string;
      username: string;
      password: string;
    }
  ): Promise<any> {
    return http.post(`/user/${username}/auth_challenge_response`, {
      auth_challenge_name: challengeName,
      auth_challenge_session_id: sessionId,
      password
    });
  }
};

export default {
  namespaced: true,
  state: _.cloneDeep(defaultState),
  mutations,
  actions
};
