import Vue from 'vue';
import VueMq from 'vue-mq';
import App from './App.vue';
import router from './router';
import store from './store';
import http from './utils/Http';
import i18n from './i18n';
import VueDOMPurifyHTML from 'vue-dompurify-html';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { AxiosInstance } from 'axios';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { Can, abilitiesPlugin } from '@casl/vue';
import { builtAbilityFor } from '@/permissions/Ability';

import './validators';
// Vue type augmentation
declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosInstance;
  }
}

Vue.use(Loading);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueDOMPurifyHTML);
// CASL
Vue.use(abilitiesPlugin, builtAbilityFor(null));
Vue.component('ascx-can', Can);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.prototype.$http = http;

Vue.use(VueMq, {
  breakpoints: {
    sm: 788,
    md: 992,
    lg: 1200,
    xl: Infinity
  }
});

library.add(fas, fal);
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
