import { has } from 'lodash';
import ErrorResponse from '@/types/ErrorResponse';
import i18n from '../i18n';
import jwt_decode from 'jwt-decode';

export const supportedLanguages = [
  'en',
  'fr',
  'es',
  'de',
  'ru',
  'pl',
  'it',
  'tr',
  'pt',
  'el',
  'nl'
];
/**
 * Makes the password validations, returns a string with the error message if password is invalid
 * or null if the password meets the criteria.
 * @param newPassword
 * @param confirmPassword
 * @param passwordLength
 * @returns string|null
 */
export function passwordValidation(
  newPassword: string,
  confirmPassword: string,
  passwordLength: number
): string | null {
  if (newPassword !== confirmPassword) {
    return 'errors.passwords_must_match';
  }

  if (
    newPassword.length < passwordLength &&
    confirmPassword.length < passwordLength
  ) {
    return 'errors.password_length';
  }
  return null;
}

/**
 * An object to string serializer suitable for requests
 * @param params An object with properties to convert
 * @returns A string with the params inline
 */
export function paramsSerializer(params: Record<string, unknown>): string {
  return Object.entries({ ...params })
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

/**
 * Clean object with null references
 * @param obj An object to clean
 * @returns the Object with null attributes removed
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function cleanObject(obj) {
  for (const attribute in obj) {
    if (obj[attribute] === null || obj[attribute] === undefined) {
      delete obj[attribute];
    }
  }
  return obj;
}

/**
 * Returns error message from the sent error.
 * @param error
 * @returns string
 */
export function getErrorMessage(error: ErrorResponse): string {
  if (
    has(error, 'response.data.status_msg_key') &&
    error.response.data.status_msg_key != null
  ) {
    if (!i18n.te(error.response.data.status_msg_key)) {
      return 'rest_api.response.error.unknown-unmapped';
    }
    return error.response.data.status_msg_key;
  }

  if (
    has(error, 'response.data.status_message') &&
    error.response.data.status_message != null
  ) {
    return error.response.data.status_message;
  }

  if (
    !has(error, 'response.data.status_code') &&
    has(error, 'response.data.status') &&
    has(error, 'response.data.title')
  ) {
    if (
      parseInt(error.response.data.status) >= 400 &&
      parseInt(error.response.data.status) < 500
    ) {
      return 'rest_api.response.error.INVALID_INPUT_DATA';
    }

    if (parseInt(error.response.data.status) >= 500) {
      return 'rest_api.response.error.GENERAL_EXCEPTION';
    }
  }

  if (has(error, 'response.data')) {
    return error.response.data;
  }

  return error.message;
}

export function getEndpoint(environment: string, isAPI: boolean): string {
  let url = '';

  if (environment && (environment != undefined || environment != '')) {
    if (
      environment.indexOf('http://') == 0 ||
      environment.indexOf('https://') == 0
    ) {
      url = environment;
    } else {
      const urlPath =
        environment.charAt(0) === '/' ? environment : `/${environment}`;
      url = `${document.location.origin}${urlPath}`;
    }
  } else {
    url = `${document.location.origin}${isAPI ? '/api/acme' : '/api/'}`;
  }

  return url;
}

export function checkTokenExpiration(token: string): number {
  const decodedToken: any = jwt_decode(token);
  // converts exp to ms
  return decodedToken.exp * 1000 - Date.now();
}

/**
 * Returns the language of systems.
 * @param defaultLang
 * @param storeLang
 * @param userLang
 * @returns string
 */
export function userLanguage(
  defaultLang: string,
  storeLang: string,
  userLang: string
): string {
  if (defaultLang == null && userLang == null) {
    return storeLang;
  }

  if (defaultLang != storeLang) {
    return storeLang;
  }

  return userLang;
}

/**
 * Capitalises a string (only first char)
 * @param str string to be capitalised
 */
export function capitalizeSingleWord(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
