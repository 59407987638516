import http from '@/utils/Http';
import * as _ from 'lodash';

const defaultState = Object.freeze({
  // needed states
});

const mutations = {
  resetState(state): void {
    Object.assign(state, _.cloneDeep(defaultState));
  }
};

const actions = {
  resetStore({ commit }): void {
    commit('resetState');
  },
  // eslint-disable-next-line
  updateUser({ commit }, { user, email }): Promise<any> {
    return http.patch(`user/${email}`, {
      ...user
    });
  },
  // eslint-disable-next-line
  getUser({ commit }, email): Promise<any> {
    return http.get(`user/${email}`);
  },
  // eslint-disable-next-line
  deleteUser({ commit }, email): Promise<any> {
    return http.delete(`user/${email}`);
  },
  /* eslint-disable-next-line */
  async resendUserInvite({ commit }, username) {
    await http.post(`user/${username}/resend_login_invitation`);
  },
  /* eslint-disable-next-line */
  async resetUserPassword({ commit }, username) {
    await http.post(`user/${username}/reset_password`);
  }
};

export default {
  namespaced: true,
  state: _.cloneDeep(defaultState),
  mutations,
  actions
};
