import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { has } from 'lodash';

import AccountRoles from '@/types/AccountRoles';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('../views/MainApp.vue'),
    meta: { authorize: [] },
    children: [
      {
        path: '/',
        name: 'Home',
        meta: {
          authorize: [],
          breadcrumbs: [
            {
              title: 'accounts.breadcrumb',
              location: {
                path: '/'
              }
            }
          ]
        },
        component: () =>
          import(
            '../components/containers/accounts-dashboard/AccountsDashboardContainer.vue'
          )
      },
      {
        path: '/settings',
        component: () =>
          import(
            '../components/containers/settings-dashboard/SettingsDashboardContainer.vue'
          ),
        children: [
          {
            path: '/',
            redirect: { name: 'MyProfile' }
          },
          {
            path: '/settings/my-profile',
            name: 'MyProfile',
            meta: {
              authorize: [],
              breadcrumbs: [
                {
                  title: 'settings.breadcrumb',
                  location: {
                    path: '/settings'
                  }
                }
              ]
            },
            component: () =>
              import(
                '../components/dashboards/my-profile-dashboard/MyProfileDashboard.vue'
              )
          },
          {
            path: '/settings/my-company',
            name: 'MyCompany',
            meta: {
              authorize: [AccountRoles.ADMIN],
              breadcrumbs: [
                {
                  title: 'settings.breadcrumb',
                  location: {
                    path: '/settings'
                  }
                }
              ]
            },
            component: () =>
              import(
                '../components/dashboards/my-company-dashboard/MyCompanyDashboard.vue'
              )
          },
          {
            path: '/settings/users',
            name: 'Users',
            meta: {
              authorize: [AccountRoles.ADMIN],
              breadcrumbs: [
                {
                  title: 'settings.breadcrumb',
                  location: {
                    path: '/settings'
                  }
                }
              ]
            },
            component: () =>
              import(
                '../components/dashboards/users-dashboard/UsersDashboard.vue'
              )
          }
        ]
      }
    ]
  },
  {
    path: '/user/login',
    name: 'login',
    meta: { notAuthenticated: true },
    component: () => import('../views/AuthView.vue')
  },
  // TODO: Create a account page route temporarily for header link
  {
    path: '/account',
    name: 'Account',
    redirect: { name: 'MyProfile' }
  },
  {
    path: '/support',
    name: 'NeedHelp',
    meta: {
      breadcrumbs: [
        {
          title: 'needHelp.breadcrumb',
          location: {
            path: '/support'
          }
        }
      ]
    },
    component: () => import('@/components/common/need-help/NeedHelp.vue')
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    meta: {
      breadcrumbs: [
        {
          title: 'breadcrumbs.terms_of_service',
          location: {
            path: '/terms-of-service'
          }
        }
      ]
    },
    props: true,
    component: () => import('@/views/TermsOfService.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    meta: {
      breadcrumbs: [
        {
          title: 'breadcrumbs.privacy-policy',
          location: {
            path: '/privacy-policy'
          }
        }
      ]
    },
    component: () => import('@/views/PrivacyPolicy.vue')
  },

  /*
   * If none path matches render a 404 page, should be
   * the last route on the routes array
   */
  { path: '*', component: () => import('../views/PageNotFoundView.vue') }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve((to, from, next) => {
  const { authorize } = to.meta;
  const isLoggedIn = router.app.$store.getters.authResolved;

  // Automatically add breadcrums
  let breadcrumbs = [];
  if (to.matched.some((record) => record.meta.breadcrumbs)) {
    breadcrumbs = to.meta.breadcrumbs;
  }
  router.app.$store.commit('updateBreadcrumbs', breadcrumbs);
  //

  /// If user is already logged it won't let him navigate to the login page
  if (to.matched.some((record) => record.meta.notAuthenticated)) {
    if (isLoggedIn) {
      next({
        path: '/'
      });
    } else {
      next();
    }
  } else if (authorize) {
    if (!isLoggedIn) {
      next({
        path: '/user/login',
        query: { redirect: to.fullPath }
      });
    } else {
      const { user } = router.app.$store.getters;
      if (user != null && has(user, 'accepted_tos')) {
        if (user.accepted_tos) {
          if (authorize.length && !authorize.includes(user.role)) {
            // If route requires a Admin user and the current user isn't return to the previous page
            next(from);
          } else {
            next();
          }
        } else {
          next({
            name: 'TermsOfService'
          });
        }
      } else {
        next({
          path: '/user/login',
          query: { redirect: to.fullPath }
        });
      }
    }
  } else {
    next();
  }
});

export default router;
