import * as VeeValidate from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import de from 'vee-validate/dist/locale/de.json';
import el from 'vee-validate/dist/locale/el.json';
import en from 'vee-validate/dist/locale/en.json';
import es from 'vee-validate/dist/locale/es.json';
import fr from 'vee-validate/dist/locale/fr.json';
import it from 'vee-validate/dist/locale/it.json';
import nl from 'vee-validate/dist/locale/nl.json';
import pl from 'vee-validate/dist/locale/pl.json';
import pt from 'vee-validate/dist/locale/pt_PT.json';
import ru from 'vee-validate/dist/locale/ru.json';
import tr from 'vee-validate/dist/locale/tr.json';
import zh from 'vee-validate/dist/locale/zh_CN.json';

const { localize, extend, configure } = VeeValidate;

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, { ...validation });
}

extend('url', {
  validate(value: string | null | undefined): boolean {
    try {
      const urlObject = new URL(value);
      return urlObject.protocol === 'http:' || urlObject.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }
});

localize({
  de,
  el,
  en,
  es,
  fr,
  it,
  nl,
  pl,
  pt,
  ru,
  tr,
  zh
});

export default VeeValidate;
