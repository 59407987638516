
  import axios from 'axios';
  import { Component, Vue, Prop, VModel, Watch } from 'vue-property-decorator';
  import AscxModal from '@rdss-mono/ascx-modal';
  import { getEndpoint } from '@/utils/Helpers';

  interface CookiePolicy {
    lang: string;
    content: string;
  }

  @Component({
    components: { AscxModal }
  })
  export default class CookiePolicyModal extends Vue {
    readonly closeIcon = require('*/images/icons/close.svg');

    @Prop({ required: true, type: String })
    readonly lang: string;

    @VModel({ type: Boolean })
    public show: boolean;

    public loading: boolean = true;

    public cookiePolicy: CookiePolicy = {
      lang: '',
      content: ''
    };

    @Watch('show')
    async onShowChanged(val: boolean): Promise<void> {
      if (val) {
        this.cookiePolicy.content = await this.getCookiePolicy(this.lang);
      }
    }

    async getCookiePolicy(lang: string): Promise<string> {
      if (this.cookiePolicy.lang !== lang || !this.cookiePolicy.content) {
        this.loading = true;
        const endpoint = getEndpoint(process.env.VUE_APP_ENDPOINT, false);
        try {
          const { data } = await axios.get(
            `${endpoint}s3/get-file?s3Path=${this.policyPath(lang)}`
          );
          this.cookiePolicy.lang = lang;
          return data;
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      } else {
        return this.cookiePolicy.content;
      }
    }

    private policyPath(lang: string): string {
      return `terms-of-service/${lang}/cookie_policy.html`;
    }
  }
