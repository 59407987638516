
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Zhou Wenye
   * Date: 31th of August 2021
   *
   * Summary: Message Box Component
   */

  import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

  @Component({
    filters: {
      timeCounter(value) {
        if (!value || value == '') {
          return '';
        }
        const minutes = Math.floor(value / 60000);
        const seconds = (value % 60000) / 1000;
        return `(${minutes}:${seconds < 10 ? '0' : ''}${seconds})`;
      }
    }
  })
  export default class MessageBoxModal extends Vue {
    @Prop({ required: true, type: String })
    readonly name: string;

    @Prop({ required: true, type: Object as () => Message })
    readonly message: Message;

    @Prop({ required: false, default: false })
    readonly noOutsideClickClose;

    @Prop({ required: false, default: 0 })
    readonly timeout: number;

    @Prop({ required: false, type: String, default: '' })
    readonly titleTextVariant: string;

    readonly step = 1000;
    public counter = null;
    public timer = null;

    @Emit('close-modal')
    modalClose(): void {
      if (this.timer) {
        clearTimeout(this.timer);
      }
    }

    private isVisible() {
      if (this.timeout > 0) {
        this.counter = this.timeout;
        this.setTimer(this);
      }
    }

    public setTimer(vm): void {
      this.timer = setTimeout(function () {
        if (vm.counter === 0) {
          vm.modalClose();
        } else {
          vm.counter -= vm.step;
          vm.setTimer(vm);
        }
      }, 1000);
    }
  }

  export interface Message {
    status: string;
    message: string;
    buttonText: string;
  }
