import axios from 'axios';
import eventBus from '@/utils/EventBus';
import { getEndpoint } from './Helpers';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
const http = axios.create({
  baseURL: getEndpoint(process.env.VUE_APP_APIENDPOINT, true)
});

/**
 * Interceptor to catch every request and add the Auth headers.
 */
http.interceptors.request.use((config) => {
  // This code should be modified to use the vue store, or some other mechanism where the
  // refresh_token and the user data will be stored.
  const localData = JSON.parse(localStorage.getItem('acme-session'));
  if (
    localData != null &&
    localData.username != null &&
    localData.id_token != null &&
    localData.access_token != null
  ) {
    config.headers.Authorization = `JWT ${localData.username} TOKENSV1 ${localData.id_token} ${localData.access_token}`;
  }

  if (process.env.VUE_APP_MOCKAPI) {
    config.headers.Authorization = process.env.VUE_APP_MOCKAPI_TOKEN;
  }

  return config;
});

/**
 * Interceptor for every response.
 * If the API returns a 401 then we try to refresh the token,
 * if the token response is an error then we log the user out.
 * If it succeds we update the refresh token
 */
http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = { ...error.config };
    if (
      error.response.status === 403 &&
      error.response.data.status_msg_key ===
        'rest_api.response.error.ACME_ACCOUNT_SUSPENDED'
    ) {
      localStorage.removeItem('acme-session');
      localStorage.removeItem('acme-refresh-token');
      eventBus.$emit('userSuspended');
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const session = JSON.parse(localStorage.getItem('acme-session'));
      // When logout/refresh_tokens request get 401, it will clear the session, and navigate the user to the login page
      if (
        session === null ||
        originalRequest.url.indexOf('refresh_tokens') > -1 ||
        originalRequest.url.indexOf('logout') > -1
      ) {
        return Promise.reject(error);
      }
      const { username } = session;
      // when other request get 401, call backend api /logout to log the user out
      http
        .post(`/user/${username}/refresh_tokens`)
        .then(({ data }) => {
          const localData = JSON.parse(localStorage.getItem('acme-session'));
          const userSession = {
            ...localData,
            id_token: data.id_token,
            access_token: data.access_token
          };
          localStorage.setItem('acme-session', JSON.stringify(userSession));
          return http(originalRequest);
        })
        .catch(() => {
          eventBus.$emit('userUnauthorized');
        });
    }

    if (error.response.status >= 500) {
      eventBus.$emit(
        'showToast',
        'label.server_error',
        'rest_api.response.error.SERVER_ERROR',
        'danger',
        error.response.status
      );
    }

    return Promise.reject(error);
  }
);

export default http;
