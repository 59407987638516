import Vue from 'vue';
import Vuex from 'vuex';
import settings from './modules/settings';
import auth from './modules/auth';
import i18n from '../i18n';
import http from '@/utils/Http';
import * as Helper from '@/utils/Helpers';
import * as _ from 'lodash';
import eventBus from '@/utils/EventBus';
import { defineRulesFor } from '@/permissions/Ability';

Vue.use(Vuex);

const defaultState = Object.freeze({
  breadcrumbs: [],
  authResolved: false,
  user: {},
  account: {},
  acmeTree: [],
  treeAccountDetail: null,
  language: 'en',
  refreshToken: null,
  footerSelection: false
});

export default new Vuex.Store({
  modules: {
    settings,
    auth
  },

  state: _.cloneDeep(defaultState),

  getters: {
    breadcrumbs: (state) => {
      return state.breadcrumbs;
    },
    authResolved: (state) => {
      return state.authResolved;
    },
    user: (state) => {
      return state.user;
    },
    language: (state) => {
      return state.language;
    },
    account: (state) => {
      return state.account;
    },
    acmeTree: (state) => state.acmeTree,
    treeAccountDetail: (state) => state.treeAccountDetail,
    footerSelection: (state) => {
      return state.footerSelection;
    }
  },
  mutations: {
    updateBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = [];
      let crumb = null;
      for (crumb in breadcrumbs) {
        state.breadcrumbs.push(breadcrumbs[crumb]);
      }
    },
    updateAuth(state, value) {
      state.authResolved = value;
    },
    updateLanguage(state, language) {
      state.language = language;
      i18n.locale = language;
    },
    updateUser(state, user) {
      state.user = user;
      this._vm.$ability.update(defineRulesFor(user));
    },
    updateRefreshToken(state, token) {
      state.refreshToken = token;
    },
    updateAccount(state, account) {
      state.account = account;
    },
    updateAcmeTree(state, acmeTree) {
      state.acmeTree = acmeTree;
    },
    treeAccountDetail(state, treeAccountDetail) {
      state.treeAccountDetail = treeAccountDetail;
    },
    resetState(state) {
      Object.assign(
        state,
        _.cloneDeep({ ...defaultState, language: state.language })
      );
    },
    updateFooterSelection(state, value) {
      state.footerSelection = value;
    }
  },
  actions: {
    async populateAcmeTree({ commit }, { queryOptions }) {
      const url = `${Helper.getEndpoint(
        process.env.VUE_APP_APIENDPOINT,
        true
      )}?${queryOptions}`;
      const { data } = await http({ url });
      commit('updateAcmeTree', data.descendant_tree);
    },
    async createAccount(ctx, { account, isVNMS }) {
      const url = `${Helper.getEndpoint(
        process.env.VUE_APP_APIENDPOINT,
        true
      )}${isVNMS ? '/vnms' : ''}`;
      const response = await http({
        method: 'post',
        url,
        data: { ...account }
      });
      this.dispatch('populateAcmeTree', { queryOptions: '' });
      return response.data;
    },
    async updateAccount(ctx, { accountUUID, account, isVNMS }) {
      const endpoint = isVNMS ? `vnms/${accountUUID}` : `${accountUUID}`;
      account.primary_contact = Helper.cleanObject(account.primary_contact);
      return http.patch(endpoint, Helper.cleanObject(account));
    },
    async deleteAccount({ commit }, { accountUUID, isVNMS }) {
      const endpoint = isVNMS ? `vnms/${accountUUID}` : `${accountUUID}`;
      await http.delete(endpoint);
      commit('treeAccountDetail', []);
    },
    /* eslint-disable-next-line */
    async resendAccountUserInvite({ commit }, { username, isVNMS }) {
      const endpoint = isVNMS ? `vnms/user/${username}` : `user/${username}`;
      await http.post(`${endpoint}/resend_login_invitation`);
    },
    async requestTreeAccountDetail({ commit }, { accountUUID, isVNMS }) {
      const endpoint = isVNMS ? `vnms/${accountUUID}` : `${accountUUID}`;
      const { data } = await http.get(endpoint);
      if (isVNMS) {
        // Append account_type because the response of vnms endpoint doesn't have that property
        data.detailed_data.config.account_type = 'vnms';

        // Until phone format for VNMS accounts is updated
        data.detailed_data.config.phone =
          data.detailed_data.config.phone.replace('|', ' ');
        data.detailed_data.config.emergency_phone =
          data.detailed_data.config.emergency_phone.replace('|', ' ');
        data.detailed_data.config.primary_contact.mobile_phone =
          data.detailed_data.config.primary_contact.mobile_phone.replace(
            '|',
            ' '
          );
        //
      }
      commit('treeAccountDetail', data.detailed_data);
      return data.detailed_data;
    },
    async cleanTreeAccountDetail({ commit }) {
      commit('treeAccountDetail', null);
    },
    /* eslint-disable-next-line */
    async updatePassword({ commit }, { username, data }) {
      await http.post(`user/${username}/change_password`, data);
    },
    async getAccount({ commit }, { accountId }) {
      const { data } = await http.get(`${accountId}`);
      commit('updateAccount', data.detailed_data);
    },
    /* eslint-disable-next-line */
    async privateContract({ commit }) {
      let contracts = [];
      // update endpoint to use role=admin&active_only=true when the API
      // supports the new query params.
      const adminUsers = await http.get(
        'user?role=admin&active_only=true&include_primary=true'
      );

      contracts = adminUsers.data.users_info.map((adminUser) => {
        return {
          name: adminUser.name,
          email_address: adminUser.email_address,
          language: adminUser.language,
          mfa_method: adminUser.mfa_method,
          mobile_phone: adminUser.mobile_phone,
          image_url: adminUser.image_url,
          role: adminUser.role
        };
      });
      return contracts;
    },
    /* eslint-disable-next-line */
    async updateCompany({ commit }, { accountId, data }) {
      await http.patch(`${accountId}`, data);
    },
    /* eslint-disable-next-line */
    async resetPassword({ commit }, { email }) {
      await http.post(`user/${email}/forgot_password`);
    },
    confirmResetPassword(
      /* eslint-disable-next-line */
      { commit },
      { email, mfaCode, newPassword }
    ) {
      return http.post(`user/${email}/confirm_forgot_password`, {
        verification_code: mfaCode,
        new_password: newPassword
      });
    },
    resetStore({ commit }) {
      commit('resetState'); // Clean current store
      this.dispatch('settings/resetStore'); // Clean settings module store
    },
    logout({ commit }) {
      localStorage.removeItem('acme-session');
      localStorage.removeItem('acme-token');
      localStorage.removeItem('refresh-token');
      localStorage.removeItem('acme-refresh-token');
      // Remove refresh token timers when the user logs out / gets logged out.
      // Line safe to delete when session management is implemented.
      eventBus.$emit('clearTimers');
      commit('updateUser', null);
      commit('updateAuth', false);
      this.dispatch('resetStore');
    }, //default value
    loadUserData({ commit }, userPayload) {
      const { forceLanguage } = userPayload;
      delete userPayload.forceLanguage;
      commit('updateUser', userPayload);
      if (
        !this.state.footerSelection ||
        (forceLanguage && forceLanguage === true)
      ) {
        commit('updateLanguage', userPayload.language);
      }
    },
    changeLanguage({ commit }, language) {
      commit('updateLanguage', language);
    },
    changeFooterSelection({ commit }, value) {
      commit('updateFooterSelection', value);
    }
  }
});
